.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.App
{
  margin: auto;

}

.container .title
{
  padding-top: 10px;
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
}

.container .subtitle
{
  padding-top: 10px;
  text-align: center;
  font-size: 14pt;
  font-weight: bold;
}

.container .text
{
  margin-top: 30px;
  text-align: center;
  font-size: 14pt;
  color: #282c34;
}

.container .text a {
  color: #367d90;
}

.container .text-descript
{
  text-align: center;
}

.container{
  position: absolute;

  width: 255px;
  height: 310px;

  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@-webkit-keyframes movetop {
  0% {
    top: 100%; }
  70% {
    top: -5%; }
  100% {
    top: 0%; } }

@-moz-keyframes movetop {
  0% {
    top: 100%; }
  70% {
    top: -5%; }
  100% {
    top: 0%; } }

@-o-keyframes movetop {
  0% {
    top: 100%; }
  70% {
    top: -5%; }
  100% {
    top: 0%; } }

@keyframes movetop {
  0% {
    top: 100%; }
  70% {
    top: -5%; }
  100% {
    top: 0%; } }

@-webkit-keyframes circle1 {
  0% {
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg); }
  25% {
    -moz-transform: rotate(140deg);
    -o-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg); }
  50% {
    -moz-transform: rotate(230deg);
    -o-transform: rotate(230deg);
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg); }
  75% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  100% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); } }

@-moz-keyframes circle1 {
  0% {
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg); }
  25% {
    -moz-transform: rotate(140deg);
    -o-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg); }
  50% {
    -moz-transform: rotate(230deg);
    -o-transform: rotate(230deg);
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg); }
  75% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  100% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); } }

@-o-keyframes circle1 {
  0% {
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg); }
  25% {
    -moz-transform: rotate(140deg);
    -o-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg); }
  50% {
    -moz-transform: rotate(230deg);
    -o-transform: rotate(230deg);
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg); }
  75% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  100% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); } }

@keyframes circle1 {
  0% {
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg); }
  25% {
    -moz-transform: rotate(140deg);
    -o-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg); }
  50% {
    -moz-transform: rotate(230deg);
    -o-transform: rotate(230deg);
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg); }
  75% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  100% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); } }

@-webkit-keyframes circle2 {
  0% {
    -moz-transform: rotate(-230deg);
    -o-transform: rotate(-230deg);
    -ms-transform: rotate(-230deg);
    -webkit-transform: rotate(-230deg);
    transform: rotate(-230deg); }
  25% {
    -moz-transform: rotate(-320deg);
    -o-transform: rotate(-320deg);
    -ms-transform: rotate(-320deg);
    -webkit-transform: rotate(-320deg);
    transform: rotate(-320deg); }
  50% {
    -moz-transform: rotate(-410deg);
    -o-transform: rotate(-410deg);
    -ms-transform: rotate(-410deg);
    -webkit-transform: rotate(-410deg);
    transform: rotate(-410deg); }
  75% {
    -moz-transform: rotate(-500deg);
    -o-transform: rotate(-500deg);
    -ms-transform: rotate(-500deg);
    -webkit-transform: rotate(-500deg);
    transform: rotate(-500deg); }
  100% {
    -moz-transform: rotate(-590deg);
    -o-transform: rotate(-590deg);
    -ms-transform: rotate(-590deg);
    -webkit-transform: rotate(-590deg);
    transform: rotate(-590deg); } }

@-moz-keyframes circle2 {
  0% {
    -moz-transform: rotate(-230deg);
    -o-transform: rotate(-230deg);
    -ms-transform: rotate(-230deg);
    -webkit-transform: rotate(-230deg);
    transform: rotate(-230deg); }
  25% {
    -moz-transform: rotate(-320deg);
    -o-transform: rotate(-320deg);
    -ms-transform: rotate(-320deg);
    -webkit-transform: rotate(-320deg);
    transform: rotate(-320deg); }
  50% {
    -moz-transform: rotate(-410deg);
    -o-transform: rotate(-410deg);
    -ms-transform: rotate(-410deg);
    -webkit-transform: rotate(-410deg);
    transform: rotate(-410deg); }
  75% {
    -moz-transform: rotate(-500deg);
    -o-transform: rotate(-500deg);
    -ms-transform: rotate(-500deg);
    -webkit-transform: rotate(-500deg);
    transform: rotate(-500deg); }
  100% {
    -moz-transform: rotate(-590deg);
    -o-transform: rotate(-590deg);
    -ms-transform: rotate(-590deg);
    -webkit-transform: rotate(-590deg);
    transform: rotate(-590deg); } }

@-o-keyframes circle2 {
  0% {
    -moz-transform: rotate(-230deg);
    -o-transform: rotate(-230deg);
    -ms-transform: rotate(-230deg);
    -webkit-transform: rotate(-230deg);
    transform: rotate(-230deg); }
  25% {
    -moz-transform: rotate(-320deg);
    -o-transform: rotate(-320deg);
    -ms-transform: rotate(-320deg);
    -webkit-transform: rotate(-320deg);
    transform: rotate(-320deg); }
  50% {
    -moz-transform: rotate(-410deg);
    -o-transform: rotate(-410deg);
    -ms-transform: rotate(-410deg);
    -webkit-transform: rotate(-410deg);
    transform: rotate(-410deg); }
  75% {
    -moz-transform: rotate(-500deg);
    -o-transform: rotate(-500deg);
    -ms-transform: rotate(-500deg);
    -webkit-transform: rotate(-500deg);
    transform: rotate(-500deg); }
  100% {
    -moz-transform: rotate(-590deg);
    -o-transform: rotate(-590deg);
    -ms-transform: rotate(-590deg);
    -webkit-transform: rotate(-590deg);
    transform: rotate(-590deg); } }

@keyframes circle2 {
  0% {
    -moz-transform: rotate(-230deg);
    -o-transform: rotate(-230deg);
    -ms-transform: rotate(-230deg);
    -webkit-transform: rotate(-230deg);
    transform: rotate(-230deg); }
  25% {
    -moz-transform: rotate(-320deg);
    -o-transform: rotate(-320deg);
    -ms-transform: rotate(-320deg);
    -webkit-transform: rotate(-320deg);
    transform: rotate(-320deg); }
  50% {
    -moz-transform: rotate(-410deg);
    -o-transform: rotate(-410deg);
    -ms-transform: rotate(-410deg);
    -webkit-transform: rotate(-410deg);
    transform: rotate(-410deg); }
  75% {
    -moz-transform: rotate(-500deg);
    -o-transform: rotate(-500deg);
    -ms-transform: rotate(-500deg);
    -webkit-transform: rotate(-500deg);
    transform: rotate(-500deg); }
  100% {
    -moz-transform: rotate(-590deg);
    -o-transform: rotate(-590deg);
    -ms-transform: rotate(-590deg);
    -webkit-transform: rotate(-590deg);
    transform: rotate(-590deg); } }

@-webkit-keyframes circle3 {
  0% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  25% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); }
  50% {
    -moz-transform: rotate(500deg);
    -o-transform: rotate(500deg);
    -ms-transform: rotate(500deg);
    -webkit-transform: rotate(500deg);
    transform: rotate(500deg); }
  75% {
    -moz-transform: rotate(590deg);
    -o-transform: rotate(590deg);
    -ms-transform: rotate(590deg);
    -webkit-transform: rotate(590deg);
    transform: rotate(590deg); }
  100% {
    -moz-transform: rotate(680deg);
    -o-transform: rotate(680deg);
    -ms-transform: rotate(680deg);
    -webkit-transform: rotate(680deg);
    transform: rotate(680deg); } }

@-moz-keyframes circle3 {
  0% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  25% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); }
  50% {
    -moz-transform: rotate(500deg);
    -o-transform: rotate(500deg);
    -ms-transform: rotate(500deg);
    -webkit-transform: rotate(500deg);
    transform: rotate(500deg); }
  75% {
    -moz-transform: rotate(590deg);
    -o-transform: rotate(590deg);
    -ms-transform: rotate(590deg);
    -webkit-transform: rotate(590deg);
    transform: rotate(590deg); }
  100% {
    -moz-transform: rotate(680deg);
    -o-transform: rotate(680deg);
    -ms-transform: rotate(680deg);
    -webkit-transform: rotate(680deg);
    transform: rotate(680deg); } }

@-o-keyframes circle3 {
  0% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  25% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); }
  50% {
    -moz-transform: rotate(500deg);
    -o-transform: rotate(500deg);
    -ms-transform: rotate(500deg);
    -webkit-transform: rotate(500deg);
    transform: rotate(500deg); }
  75% {
    -moz-transform: rotate(590deg);
    -o-transform: rotate(590deg);
    -ms-transform: rotate(590deg);
    -webkit-transform: rotate(590deg);
    transform: rotate(590deg); }
  100% {
    -moz-transform: rotate(680deg);
    -o-transform: rotate(680deg);
    -ms-transform: rotate(680deg);
    -webkit-transform: rotate(680deg);
    transform: rotate(680deg); } }

@keyframes circle3 {
  0% {
    -moz-transform: rotate(320deg);
    -o-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg); }
  25% {
    -moz-transform: rotate(410deg);
    -o-transform: rotate(410deg);
    -ms-transform: rotate(410deg);
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg); }
  50% {
    -moz-transform: rotate(500deg);
    -o-transform: rotate(500deg);
    -ms-transform: rotate(500deg);
    -webkit-transform: rotate(500deg);
    transform: rotate(500deg); }
  75% {
    -moz-transform: rotate(590deg);
    -o-transform: rotate(590deg);
    -ms-transform: rotate(590deg);
    -webkit-transform: rotate(590deg);
    transform: rotate(590deg); }
  100% {
    -moz-transform: rotate(680deg);
    -o-transform: rotate(680deg);
    -ms-transform: rotate(680deg);
    -webkit-transform: rotate(680deg);
    transform: rotate(680deg); } }

.inl-load {
  position: relative;
  overflow: hidden; }
  .inl-load.small {
    width: 100px;
    height: 100px; }
    .inl-load.small .ring.ring-1 {
      border: 2px solid #1da1f2;
      border-top-color: transparent; }
    .inl-load.small .ring.ring-2 {
      border: 2px solid #ff0000;
      border-left-color: transparent; }
    .inl-load.small .ring.ring-3 {
      border: 2px solid #000;
      border-right-color: transparent; }
  .inl-load.medium {
    width: 200px;
    height: 200px; }
    .inl-load.medium .ring.ring-1 {
      border: 6px solid #1da1f2;
      border-top-color: transparent; }
    .inl-load.medium .ring.ring-2 {
      border: 6px solid #ff0000;
      border-left-color: transparent; }
    .inl-load.medium .ring.ring-3 {
      border: 6px solid #000;
      border-right-color: transparent; }
  .inl-load.large {
    width: 400px;
    height: 400px; }
    .inl-load.large .ring.ring-1 {
      border: 12px solid #1da1f2;
      border-top-color: transparent; }
    .inl-load.large .ring.ring-2 {
      border: 12px solid #ff0000;
      border-left-color: transparent; }
    .inl-load.large .ring.ring-3 {
      border: 12px solid #000;
      border-right-color: transparent; }
  .inl-load .ring {
    position: absolute;
    border-radius: 100%; }
    .inl-load .ring.ring-1 {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-animation: circle1 infinite 2.2s linear;
      -moz-animation: circle1 infinite 2.2s linear;
      -o-animation: circle1 infinite 2.2s linear;
      animation: circle1 infinite 2.2s linear;
      -moz-transform: rotate(50deg);
      -o-transform: rotate(50deg);
      -ms-transform: rotate(50deg);
      -webkit-transform: rotate(50deg);
      transform: rotate(50deg); }
    .inl-load .ring.ring-2 {
      top: 5%;
      left: 5%;
      right: 5%;
      bottom: 5%;
      -webkit-animation: circle2 infinite 2.1s linear;
      -moz-animation: circle2 infinite 2.1s linear;
      -o-animation: circle2 infinite 2.1s linear;
      animation: circle2 infinite 2.1s linear;
      -moz-transform: rotate(-230deg);
      -o-transform: rotate(-230deg);
      -ms-transform: rotate(-230deg);
      -webkit-transform: rotate(-230deg);
      transform: rotate(-230deg); }
    .inl-load .ring.ring-3 {
      top: 10%;
      left: 10%;
      right: 10%;
      bottom: 10%;
      -webkit-animation: circle3 infinite 2s linear;
      -moz-animation: circle3 infinite 2s linear;
      -o-animation: circle3 infinite 2s linear;
      animation: circle3 infinite 2s linear;
      -moz-transform: rotate(320deg);
      -o-transform: rotate(320deg);
      -ms-transform: rotate(320deg);
      -webkit-transform: rotate(320deg);
      transform: rotate(320deg); }
    .inl-load .ring.logo {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 60%;
      max-height: 60%;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto; }

.container {
  -webkit-animation: movetop 1 1s ease-out;
  -moz-animation: movetop 1 1s ease-out;
  -o-animation: movetop 1 1s ease-out;
  animation: movetop 1 1s ease-out;
  top: 0%; }
